import React from 'react';
import Close from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useStyles } from './styles';

export const CustomDialog = ({
    title, handleClose,
    hideCloseIcon, content,
    actions, open, DialogTitleProps = {}, ...other
}) => {
    const {
        dialogTitle, dialogContent,
        closeIcon, dialogActions
    } = useStyles({});

    return (
        <Dialog
            onClose={handleClose}
            open={Boolean(open)}
            {...other}
        >
            <DialogTitle classes={{ root: dialogTitle }} {...DialogTitleProps}>
                {title}
                {
                    !hideCloseIcon
                    && <Close onClick={handleClose} classes={{ root: closeIcon }}/>
                }
            </DialogTitle>
            <DialogContent classes={{ root: dialogContent }}>
                {content}
            </DialogContent>
            {
                actions && (
                    <DialogActions classes={{ root: dialogActions }} >
                        { actions }
                    </DialogActions>
                )
            }
        </Dialog>
    );
};
